jQuery(($) => {
    const $CLICKABLE_CARDS = jQuery('.js-clickable-card, .elementor-grid-item.tg_case_study, .elementor-grid-item.post')
    const CARD_LINK_SELECTORS = 'a.card__link, a.elementor-post__read-more, a.elementor-post__thumbnail__link'

    /**
     * Add cursor style to cards with links
     */
    $CLICKABLE_CARDS.each( function() {
        if ($(this).find(CARD_LINK_SELECTORS).length > 0) {
            $(this).css({
                cursor: 'pointer'
            });
        }
    })
    
    /**
     * Clone click handler to card wrapper node
     */
    $CLICKABLE_CARDS.on('click', function() {
      $(this).find(CARD_LINK_SELECTORS)[0].click()
    })
})
