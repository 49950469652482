
const themeHeader = document.querySelector('.elementor-location-header');
const params = (new URL(location.href)).searchParams;
const elementId = params.get('dataserv-link');
const element = document.getElementById(`dataserv-link-${elementId}`);

function scrollIntoView(element) {
    let navbarHeight = themeHeader.clientHeight;
    let offsetTop  = element.getBoundingClientRect().top - navbarHeight;

    window.scrollTo({top: offsetTop, behavior: 'smooth'})
}

if (element) {
    setTimeout(() => scrollIntoView(element), 20)
}
